<template>
  <div id="home" class="mx-auto">
    <MoleculesLoadingOverlay v-if="loading" />
    <Organisms v-else :organisms="organisms" />
    <OrganismsModalForm>
      <header class="mb-4">
        <h2 class="mb-4 text-4xl font-semibold text-black font-figtree">
          Become a VIP
        </h2>
        <p v-if="!isFormSubmitted" class="text-lg text-gray-500">
          Be the first to know about special offers and updates.
        </p>
      </header>
      <FormsHubSpotForm
        :form="{ id: '8ce598ee-40c0-48fb-b83d-b3a3ea49b2ce' }"
        @submit="isFormSubmitted = true"
      />
    </OrganismsModalForm>
  </div>
</template>

<style>
.organism-slider {
  @apply mb-0;
}

.heading-transition {
  h2 {
    @apply font-cg text-3xl sm:text-[60px];
  }
}

#collections-intro-text {
  @apply max-w-4xl mx-auto mb-10 px-4;

  h2 {
    @apply font-cg text-fr-text-dark font-medium leading-none text-2xl lg:text-[45px];
  }
}

#lifefullness-logo-text {
  @apply max-w-4xl mx-auto mb-10 px-4;

  img {
    @apply mb-2 mx-auto;
  }

  h2 {
    @apply font-semibold font-figtree text-bc-warm-gray leading-normal text-2xl lg:text-[33px];
  }

  .buttons {
    @apply justify-center mt-8;
  }
}

#featured-community {
  @apply mb-0;

  .text {
    h2 {
      @apply font-cg text-[2.5rem] sm:text-[3.5rem];
    }
  }
}

#collections-columns {
  @apply px-4 mb-28;

  .image-link {
    @apply hover:opacity-90;
  }

  img {
    @apply mb-4;
  }

  .title {
    @apply mb-4;

    /* Hiding until we know if we are using collection icons or not
    &::before {
      @apply w-14 h-14 bg-no-repeat bg-contain inline-block relative top-4;
      content: "";
    }*/
  }

  .split-content {
    h2 {
      @apply text-center font-cg md:text-6xl text-4xl;
    }

    .description {
      @apply mb-4;
    }
  }

  .description p {
    @apply text-center text-lg font-figtree;
    letter-spacing: 0.2px;
    line-height: 1.222em;
  }

  .buttons {
    @apply !justify-center;
  }

  #wee-cottage-column {
    .title {
      @apply text-bc-wc-green;

      &::before {
        background-image: url("/img/wee-cottage-icon.svg");
      }
    }
  }

  #easyhouse-column {
    .title {
      @apply text-bc-ezh-blue;

      &::before {
        background-image: url("/img/easyhouse-icon.svg");
      }
    }
  }

  #limited-edition-column {
    .title {
      @apply text-bc-le-gray;

      &::before {
        background-image: url("/img/limited_edition-icon.svg");
      }
    }
  }

  .buttons a {
    @apply uppercase;
  }
}

#testimonials-slider,
.testimonial-cta {
  @apply bg-gradient-to-r from-[#1685a7] to-[#0e6ea2];
}

#testimonials-slider::before {
  @apply block w-full h-10 bg-white bg-fr-beige-med;;
  content: "";
}

.testimonial-imageless .px-16 img {
  display: none;
}

.virtual-tour {
  @apply mb-0;
  h2 {
    @apply text-4xl sm:text-6xl;
  }

  .buttons a {
    @apply uppercase;
  }
}

#marshall-fire-rebuilding {
  @apply py-7;
}

.text-cta {
  h2 {
    @apply text-4xl font-cg;
  }

  p {
    @apply leading-8;

    a {
      @apply text-sm text-bc-dark-blue underline;
    }
  }

  .buttons a {
    @apply uppercase;
  }
}
</style>

<script setup>
//import { useQuery } from "@vue/apollo-composable";
import { useHead } from "#imports";
import { useAsyncQuery } from "#imports";
import { GET_ORGANISMS } from "~/graphql/GetOrganisms";
import { storeToRefs } from "pinia";
const breadcrumbs = useBreadcrumbs();
const { breadcrumbsList } = storeToRefs(breadcrumbs);
const modalForm = useModalForm();
const isFormSubmitted = ref(false);

const { data, pending, error } = await useAsyncQuery(GET_ORGANISMS, {
  uri: "/",
});

const organisms = computed(() => {
  return data.value?.organisms?.hlOrganisms?.hlOrganism ?? [];
});

onBeforeMount(() => {
  useHead({
    link: organisms.value.flatMap((organism) =>
      organism.item
        ? organism.item.flatMap((item) =>
            item.backgroundImage
              ? [
                  {
                    rel: "preload",
                    href: item.backgroundImage.sourceUrl,
                    as: "image",
                  },
                ]
              : [],
          )
        : [],
    ),
  });
});

breadcrumbsList.value = [{ title: "Home", link: "" }];

const loading = pending;

onMounted(() => {
  window.addEventListener(
    "hashchange",
    () => {
      if (window.location.hash === "#modal-form") {
        modalForm.openModal();
        window.location.hash = "";
      }
    },
    false,
  );

  addTriggers();
});

const addTriggers = () => {
  const modalFormTriggers = document.querySelectorAll(
    "#cta-be-the-first .button-1, #cta-be-the-first a[href='#modal-form']",
  );

  modalFormTriggers.forEach((node) => {
    // Clone & replace node to remove existing event listeners
    const clonedNode = node.cloneNode(true);
    node.parentNode.replaceChild(clonedNode, node);

    clonedNode.addEventListener("click", (event) => {
      // Add event listener
      event.preventDefault();
      modalForm.openModal();
    });
  });
};

onUpdated(() => {
  addTriggers();
});
</script>
